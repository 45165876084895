<template>
  <div>
    <div class="reset-password-success-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-5 p-0">
            <div class="form-wrapper left-panel position-relative">
              <div class="logo">
                <router-link
                  :to="{ name: 'anonymousRoute-home' }"
                  class="logo-image"
                >
                  <img
                    src="/img/digital-staging-logo.svg"
                    alt="Digital Staging"
                  />
                </router-link>
              </div>
              <div
                class="reset-password-success-banner-mobile d-xs-block d-sm-block d-md-block d-lg-none"
              >
                <img src="/img/login-banner-mobile.png" alt="Sign up Banner" />
              </div>
              <div class="reset-password-wrapper d-flex justify-content-center">
                <div class="success-wrapper text-center">
                  <img
                    class="mb-4"
                    src="/img/icons/icon-email-success.svg"
                    alt="Register Success"
                  />
                  <h2 class="mb-3 blue-text font-weight-bold">
                    メールをご確認ください
                  </h2>
                  <p class="mb-5 grey-text">
                    ご登録のアドレスにパスワード再設定用のリンクを送信しました
                  </p>
                  <router-link
                    class="btn d-block mt-3 grey-text"
                    :to="{ name: 'anonymousRoute-login' }"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
                    />
                    <span>ログイン画面に戻る</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-7 p-0 d-none d-lg-block banner-wrap">
            <img src="/img/login-banner.png" alt="Sign up Banner" />
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainFooter from '../components/customer/Main-Footer.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Reset Password',
    };
  },

  components: {
    'main-footer': mainFooter,
  },
};
</script>

<style scoped>
.reset-password-success-wrapper .form-wrapper {
  padding: 42px;
  height: 100vh;
}

@media (max-width: 991.98px) {
  .reset-password-success-wrapper .form-wrapper {
    height: auto;
  }
}

.reset-password-success-wrapper .form-wrapper .lets-start {
  background-color: #00adee;
  color: #fff;
  border-radius: 25px;
  position: absolute;
  right: -178px;
  padding: 15px 12px;
  width: 175px;
  text-align: center;
  height: 53px;
  margin: 5px 0;
}

.reset-password-success-wrapper .form-wrapper .reset-password-wrapper {
  padding: 40px 40px 10px 140px;
}

.reset-password-success-wrapper
  .form-wrapper
  .reset-password-wrapper
  .title-form {
  font-weight: 500;
}

.reset-password-success-wrapper
  .form-wrapper
  .reset-password-wrapper
  form
  .form-group {
  position: relative;
}

.reset-password-success-wrapper
  .form-wrapper
  .reset-password-wrapper
  form
  .btn-google {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bfbebe;
  border-radius: 100px;
  color: #707070;
}

.reset-password-success-wrapper
  .form-wrapper
  .reset-password-wrapper
  form
  .option-links
  p
  a,
.reset-password-success-wrapper
  .form-wrapper
  .reset-password-wrapper
  form
  .option-links
  a {
  color: #034ea1;
  text-decoration: underline !important;
}

.reset-password-success-wrapper .form-wrapper .form-footer {
  padding: 10px 40px 10px 140px;
  position: absolute;
  margin: 1em auto;
  overflow: hidden;
  bottom: 0;
  width: 80%;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
}

.reset-password-success-wrapper .form-wrapper .form-footer ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1px;
  list-style: none;
  padding-left: 0;
}

.reset-password-success-wrapper .form-wrapper .form-footer ul li {
  flex-grow: 1;
  flex-basis: auto;
  margin: 0.25em 0;
  padding: 0 1em;
  text-align: center;
  border-left: 1px solid #ccc;
}

.reset-password-success-wrapper .form-wrapper .form-footer ul li:first-child {
  border-left: 0 !important;
}

.reset-password-success-wrapper .form-wrapper .form-footer ul li a {
  text-decoration: underline !important;
  color: #9b9b9b;
}

.reset-password-success-wrapper .banner-wrap {
  height: 100vh;
  overflow: hidden;
  padding: 0;
}

.reset-password-success-wrapper .banner-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left center;
  object-position: left center;
}

@media (max-width: 991.98px) {
  .reset-password-success-wrapper .form-wrapper {
    padding: 0 !important;
  }

  .reset-password-success-wrapper .form-wrapper .logo {
    padding: 20px;
  }

  .reset-password-success-wrapper .form-wrapper .logo .logo-image img {
    width: 172px;
  }

  .reset-password-success-wrapper .form-wrapper .logo .lets-start {
    right: 20px !important;
    padding: 8px 10px;
    height: auto;
    margin: 0;
  }

  .reset-password-success-wrapper .form-wrapper .reset-password-wrapper {
    padding: 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    position: relative;
    top: -35px;
    background: #fff;
  }

  .reset-password-success-wrapper .form-wrapper .form-footer {
    padding: 20px;
    position: relative !important;
    width: 100%;
  }

  .reset-password-success-wrapper
    .form-wrapper
    .reset-password-success-banner-mobile
    img {
    width: 100%;
    height: 251px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .reset-password-success-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .reset-password-success-wrapper .form-wrapper .reset-password-wrapper,
  .reset-password-success-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 0 !important;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .reset-password-success-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .reset-password-success-wrapper .form-wrapper .reset-password-wrapper,
  .reset-password-success-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 40px !important;
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 0.5px solid #b8b8b8;
}

.separator:not(:empty)::before {
  margin-right: 8px;
}

.separator:not(:empty)::after {
  margin-left: 8px;
}

.success-wrapper {
  margin-top: 100px;
}

@media (max-width: 991px) {
  .success-wrapper {
    margin-top: 50px;
  }
}

@media (max-width: 425px) {
  .success-wrapper {
    margin-top: 20px;
  }
}
</style>
